/* eslint-disable */
import "./PersonalInfo.css";
import StateDropdown from "./components/state-dropdown";
import { PatternFormat } from 'react-number-format';

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function PersonalInfo({ next, handleChange, values, readonly=false }) {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    let nextButton;
    if (next) {
        nextButton = 
            <Row>
                <Form.Group as={Col} md="12">
                    <Button type="submit">Next</Button>
                </Form.Group>
            </Row>
    } else {
        nextButton = <Row></Row>
    }

    return (
        <div className="container-lg personal-info">
            <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col} md="4">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control type="text" id="firstName" maxLength={30} required onChange={handleChange('firstName')} value={values.firstName} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a first name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control type="text" id="middleName" maxLength={30} onChange={handleChange('middleName')} value={values.middleName} disabled={readonly} />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control type="text" id="lastName" maxLength={30} required onChange={handleChange('lastName')} value={values.lastName} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a last name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Date of Birth *</Form.Label>
                        <PatternFormat id="dateOfBirth" format="##/##/####" required onChange={handleChange('dateOfBirth')} value={values.dateOfBirth} disabled={readonly} className="form-control" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid date of birth.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Social Security Number (XXX-XX-XXXX) *</Form.Label>
                        <PatternFormat id="ssn" format="###-##-####" required onChange={handleChange('ssn')} value={values.ssn} disabled={readonly} className="form-control" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid social security number.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="8">
                        <Form.Label>Street Address *</Form.Label>
                        <Form.Control type="text" id="street" maxLength={60} required onChange={handleChange('street')} value={values.street} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid street address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>City *</Form.Label>
                        <Form.Control type="text" id="city" maxLength={30} required onChange={handleChange('city')} value={values.city} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label>State *</Form.Label>
                        <StateDropdown name="addressState" onChange={handleChange('addressState')} value={values.addressState} disabled={readonly} required={true} />
                        <Form.Control.Feedback type="invalid">
                            Please select a state.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                        <Form.Label>Zip *</Form.Label>
                        <Form.Control type="text" id="zip" maxLength={5} pattern="[0-9]{5}" required onChange={handleChange('zip')} value={values.zip} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid zip.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="3">
                        <Form.Label>Phone Number (XXX-XXX-XXXX) *</Form.Label>
                        <PatternFormat id="phone" format="(###) ###-####" required onChange={handleChange('phone')} value={values.phone} disabled={readonly} className="form-control" />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid phone number.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Do you have a commercial driver's license? *</Form.Label>
                        <Form.Group>
                            <Form.Check required inline type="radio" id="haveCdl" label="Yes" name="haveCdl" onChange={handleChange('haveCdl')} checked={values.haveCdl === 'Y'} value="Y" disabled={readonly} />
                            <Form.Check required inline type="radio" id="haveCdl" label="No" name="haveCdl" onChange={handleChange('haveCdl')} checked={values.haveCdl === 'N'} value="N" disabled={readonly} />
                        </Form.Group>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Driver's License Number *</Form.Label>
                        <Form.Control type="text" id="cdlNumber" maxLength={30} required onChange={handleChange('cdlNumber')} value={values.cdlNumber} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid driver's license number.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label>State of Issue *</Form.Label>
                        <StateDropdown name="dlState" onChange={handleChange('dlState')} value={values.dlState} disabled={readonly} required={true} />
                        <Form.Control.Feedback type="invalid">
                            Please select a state.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label>Expiration Date *</Form.Label>
                        <PatternFormat id="dateOfIssue" format="##/##/####" required onChange={handleChange('dateOfIssue')} value={values.dateOfIssue} disabled={readonly} className="form-control" />
                    </Form.Group>
                </Row>
                { nextButton }
            </Form>
        </div>
    );
};
