/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { PatternFormat } from 'react-number-format';
import { API } from 'aws-amplify';

import PersonalInfo from './PersonalInfo';
import StateDropdown from "./components/state-dropdown";

import CDLForm from './img/CDL_Form.png'

export default function Application({ previous, next, handleChange, values, readonly=false, employer }) {

    async function sendData() {
        return API.post('APIG', '/', {
            body: values
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {            
            event.stopPropagation();
            setValidated(true);
        } else {
            sendData()
                .then(function(response) {
                    next();
                })
                .catch(function(error) {
                    //TODO: handle error properly
                    next();
                });
        }
        
    }

    const prevButton = previous ? <Button onClick={previous} className="btn-secondary">Back</Button> : <></>;
    const nextButton = next ? <Button type="submit">Next</Button> : <></>;
    const buttons = 
                    <Row>
                        <Form.Group as={Col} md="12">
                            {prevButton}
                            {nextButton}
                        </Form.Group>
                    </Row>
                    
    return(
        <div className="container-lg personal-info">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <PersonalInfo next={undefined} handleChange={handleChange} values={values} readonly={readonly} />

                {/* Driving history */}
                <Row>
                    <strong>TO BE COMPLETED FOR ALL POSITIONS INVOLVING THE OPERATION OF A MOTOR VEHICLE OR OFF-ROAD EQUIPMENT</strong>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>Has your driver's license been revoked or suspended in the last 3 years? </Form.Label>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-revoked" id="license-revoked-yes" required onChange={handleChange('licenseRevoked')} checked={values.licenseRevoked === "Y"} value="Y" disabled={readonly} />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-revoked" id="license-revoked-no" onChange={handleChange('licenseRevoked')} checked={values.licenseRevoked === "N"} value="N" disabled={readonly} />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label>If yes, explain:</Form.Label>
                        <Form.Control as="textarea" name="explain-revoked" onChange={handleChange('explainRevoked')} value={values.explainRevoked} disabled={readonly} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>How many years have you been driving?</Form.Label>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-length" id="license-length-1" required onChange={handleChange('licenseLength')} checked={values.licenseLength === "1"} value="1" disabled={readonly} />
                            <Form.Check.Label>Less than 1 year</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-length" id="license-length-23" onChange={handleChange('licenseLength')} checked={values.licenseLength === "23"} value="23" disabled={readonly} />
                            <Form.Check.Label>2 - 3 years</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-length" id="license-length-3" onChange={handleChange('licenseLength')} checked={values.licenseLength === "3"} value="3" disabled={readonly} />
                            <Form.Check.Label>Over 3 years</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>Any restrictions on your license?</Form.Label>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-restrictions" id="license-restrictions-yes" required onChange={handleChange('licenseRestrictions')} checked={values.licenseRestrictions === "Y"} value="Y" disabled={readonly} />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-restrictions" id="license-restrictions-no" onChange={handleChange('licenseRestrictions')} checked={values.licenseRestrictions === "N"} value="N" disabled={readonly} />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label>If yes, explain:</Form.Label>
                        <Form.Control as="textarea" name="explain-restrictions" onChange={handleChange('explainRestrictions')} value={values.explainRestrictions} disabled={readonly} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>Did you have any <strong>moving traffic violations (not parking)</strong> in the last 3 years?</Form.Label>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="traffic-violations" id="traffic-violations-yes" required onChange={handleChange('trafficViolations')} checked={values.trafficViolations === "Y"} value="Y" disabled={readonly} />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="traffic-violations" id="traffic-violations-no" onChange={handleChange('trafficViolations')} checked={values.trafficViolations === "N"} value="N" disabled={readonly} />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>Did you have any accidents in the last 3 years?</Form.Label>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="accidents" id="accidents-yes" required onChange={handleChange('accidents')} checked={values.accidents === "Y"} value="Y" disabled={readonly} />
                            <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="accidents" id="accidents-no" onChange={handleChange('accidents')} checked={values.accidents === "N"} value="N" disabled={readonly} />
                            <Form.Check.Label>No</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label className='check-label'>Type of drivers license you hold? </Form.Label>
                        <Form.Text className='check-label'>Regular</Form.Text>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-type" id="license-type-d" required onChange={handleChange('licenseType')} checked={values.licenseType === "D"} value="D" disabled={readonly} />
                            <Form.Check.Label>D</Form.Check.Label>
                        </Form.Check>
                        <Form.Text className='check-label'>Commercial Operator Class</Form.Text>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-type" id="license-type-a" onChange={handleChange('licenseType')} checked={values.licenseType === "A"} value="A" disabled={readonly} />
                            <Form.Check.Label>A</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-type" id="license-type-b" onChange={handleChange('licenseType')} checked={values.licenseType === "B"} value="B" disabled={readonly} />
                            <Form.Check.Label>B</Form.Check.Label>
                        </Form.Check>
                        <Form.Check inline>
                            <Form.Check.Input type="radio" name="license-type" id="license-type-c" onChange={handleChange('licenseType')} checked={values.licenseType === "C"} value="C" disabled={readonly} />
                            <Form.Check.Label>C</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Row>
                {['A', 'B', 'C'].includes(values.licenseType) && (
                    <>
                        <Row>
                            <Form.Group>
                                <Form.Label className='check-label'>Endorsements</Form.Label>
                                <Form.Check inline>
                                    <Form.Check.Input type="checkbox" name="endorsement-tanker" id="endorsement-tanker" onChange={handleChange('endorsementTanker')} checked={values.endorsementTanker} disabled={readonly} />
                                    <Form.Check.Label>Tanker</Form.Check.Label>
                                </Form.Check>
                                <Form.Check inline>
                                    <Form.Check.Input type="checkbox" name="endorsement-hazmat" id="endorsement-hazmat" onChange={handleChange('endorsementHazmat')} checked={values.endorsementHazmat} disabled={readonly} />
                                    <Form.Check.Label>HazMat</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Row>
                        <Row>
                            <hr/>
                            <Form.Group>
                                <Form.Label>Residency</Form.Label>
                                <p>List any additional address history for the last three (3) years. If no previous address history is available, you may bypass this section.</p>
                            </Form.Group>
                        </Row>


                        <Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control type="text" id='formerAddrStreet1' maxLength={30} onChange={handleChange('formerAddrStreet1')} value={values.formerAddrStreet1} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" id="formerCity1" maxLength={30} onChange={handleChange('formerCity1')} value={values.formerCity1} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>State</Form.Label>
                                <StateDropdown name="formerState1" onChange={handleChange('formerState1')} value={values.formerState1} disabled={readonly}></StateDropdown>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control type="text" id="formerZipCode1" maxLength={5} onChange={handleChange('formerZipCode1')} value={values.formerZipCode1} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="1">
                                <Form.Label>How Long?</Form.Label>
                                <Form.Control type="text" id="formerAddrLength1" maxLength={2} onChange={handleChange('formerAddrLength1')} value={values.formerAddrLength1} disabled={readonly} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control type="text" id='formerAddrStreet2' maxLength={30} onChange={handleChange('formerAddrStreet2')} value={values.formerAddrStreet2} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" id="formerCity2" maxLength={30} onChange={handleChange('formerCity2')} value={values.formerCity2} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>State</Form.Label>
                                <StateDropdown name="formerState2" onChange={handleChange('formerState2')} value={values.formerState2} disabled={readonly}></StateDropdown>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control type="text" id="formerZipCode2" maxLength={5} onChange={handleChange('formerZipCode2')} value={values.formerZipCode2} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="1">
                                <Form.Label>How Long?</Form.Label>
                                <Form.Control type="text" id="formerAddrLength2" maxLength={2} onChange={handleChange('formerAddrLength2')} value={values.formerAddrLength2} disabled={readonly} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control type="text" id='formerAddrStreet3' maxLength={30} onChange={handleChange('formerAddrStreet3')} value={values.formerAddrStreet3} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" id="formerCity3" maxLength={30} onChange={handleChange('formerCity3')} value={values.formerCity3} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>State</Form.Label>
                                <StateDropdown name="formerState3" onChange={handleChange('formerState3')} value={values.formerState3} disabled={readonly}></StateDropdown>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control type="text" id="formerZipCode3" maxLength={5} onChange={handleChange('formerZipCode3')} value={values.formerZipCode3} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="1">
                                <Form.Label>How Long?</Form.Label>
                                <Form.Control type="text" id="formerAddrLength3" maxLength={2} onChange={handleChange('formerAddrLength3')} value={values.formerAddrLength3} disabled={readonly} />
                            </Form.Group>
                        </Row>


                        <Row>
                            <hr/>
                            <Form.Group>
                                <Form.Label className='check-label'>Prior Pre-Employment Drug Or Alcohol Tests:</Form.Label>
                                <span>Have you ever tested positive or refused to test on any pre-employment drug or alcohol test administered by an employer to which you applied for work, but did not obtain, safety-sensitive transportation work covered by DOT agency drug and alcohol testing rules during the past three years?*</span>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Check inline>
                                    <Form.Check.Input type="radio" name="failedDrugTest" id="failedDrugTest-yes" required onChange={handleChange('failedDrugTest')} checked={values.failedDrugTest === "Y"} value="Y" disabled={readonly} />
                                    <Form.Check.Label>Yes</Form.Check.Label>
                                </Form.Check>
                                <Form.Check inline>
                                    <Form.Check.Input type="radio" name="failedDrugTest" id="failedDrugTest-no" onChange={handleChange('failedDrugTest')} checked={values.failedDrugTest === "N"} value="N" disabled={readonly} />
                                    <Form.Check.Label>No</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Row>
                        <Row>
                            <hr/>
                            <Form.Group>
                                <Form.Check.Label className='check-label'>If you don't have any prior driving experience please check this box.</Form.Check.Label>
                                <Form.Check inline>
                                    <Form.Check.Input type="checkbox" name="noDrivingExperience" id="noDrivingExperience-no" onChange={handleChange('noDrivingExperience')} checked={values.noDrivingExperience} disabled={readonly} />
                                </Form.Check>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>Driving History</Form.Label>
                            </Form.Group>
                        </Row>
                        
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Equipment Class</Form.Label>
                                <Form.Control type="text" id="equipmentClass1" maxLength={30} onChange={handleChange('equipmentClass1')} value={values.equipmentClass1} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven From Date</Form.Label>
                                <PatternFormat id="drivenFromDate1" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenFromDate1')} value={values.drivenFromDate1} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven To Date</Form.Label>
                                <PatternFormat id="drivenToDate1" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenToDate1')} value={values.drivenToDate1} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Approx. Total Mileage</Form.Label>
                                <Form.Control type="text" id="approxTotalMileage1" maxLength={7} onChange={handleChange('approxTotalMileage1')} value={values.approxTotalMileage1} disabled={readonly} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Equipment Class</Form.Label>
                                <Form.Control type="text" id="equipmentClass2" maxLength={30} onChange={handleChange('equipmentClass2')} value={values.equipmentClass2} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven From Date</Form.Label>
                                <PatternFormat id="drivenFromDate2" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenFromDate2')} value={values.drivenFromDate2} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven To Date</Form.Label>
                                <PatternFormat id="drivenToDate2" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenToDate2')} value={values.drivenToDate2} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Approx. Total Mileage</Form.Label>
                                <Form.Control type="text" id="approxTotalMileage2" maxLength={7} onChange={handleChange('approxTotalMileage2')} value={values.approxTotalMileage2} disabled={readonly} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Equipment Class</Form.Label>
                                <Form.Control type="text" id="equipmentClass3" maxLength={30} onChange={handleChange('equipmentClass3')} value={values.equipmentClass3} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven From Date</Form.Label>
                                <PatternFormat id="drivenFromDate3" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenFromDate3')} value={values.drivenFromDate3} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven To Date</Form.Label>
                                <PatternFormat id="drivenToDate3" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenToDate3')} value={values.drivenToDate3} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Approx. Total Mileage</Form.Label>
                                <Form.Control type="text" id="approxTotalMileage3" maxLength={7} onChange={handleChange('approxTotalMileage3')} value={values.approxTotalMileage3} disabled={readonly} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Equipment Class</Form.Label>
                                <Form.Control type="text" id="equipmentClass4" maxLength={30} onChange={handleChange('equipmentClass4')} value={values.equipmentClass4} disabled={readonly} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven From Date</Form.Label>
                                <PatternFormat id="drivenFromDate4" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenFromDate4')} value={values.drivenFromDate4} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Driven To Date</Form.Label>
                                <PatternFormat id="drivenToDate4" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('drivenToDate4')} value={values.drivenToDate4} disabled={readonly} className="form-control" />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Approx. Total Mileage</Form.Label>
                                <Form.Control type="text" id="approxTotalMileage4" maxLength={7} onChange={handleChange('approxTotalMileage4')} value={values.approxTotalMileage4} disabled={readonly} />
                            </Form.Group>
                        </Row>
                            
                        <Row>
                            <hr/>
                            <strong>General Consent for Limited Queries of Federal Motor Carrier Safety Administration (FMCSA) Drug and Alcohol Clearinghouse</strong>
                            <p>I, <strong>{values.firstName + ' ' + values.lastName}</strong>, hereby provide consent to {employer.longName} ("the Company"), for the duration of my employment, to conduct a limited Query of the FMCSA Commercial Driver's License Drug and Alcohol Clearinghouse (Clearinghouse) to determine whether drug or alcohol violation information about me exists in the Clearinghouse, during the duration of my employment.</p>
                            <p>I understand that if the limited query conducted by the Company indicates that drug or alcohol information about me exists in the Clearinghouse, FMCSA will not disclose that information to the company without first obtaining additional specific consent from me.</p>
                            <p>I further understand that if I refuse to provide consent for the Company to conduct a limited query of the Clearinghouse, the Company must prohibit me from performing safety-sensitive functions, including driving a commercial motor vehicle, as required by FMCSA's drug and alcohol regulations.</p>
                        </Row>

                        {['alm'].includes(values.clientId) && (
                        <>
                            <Row>
                                <hr/>
                                <strong>CDL Credentials - Safety Performance History Records Request</strong>
                                <p>The previous DOT-requlated employer release requested information on receipt of the written inquiry within 30 days after the request. 49 CFR §391.23. <strong>This form must be completed for each employer within the past three (3) years in which you were subject to drug and alcohol testing requirements of 49 CFR Part 40. If you are currently employed, please leave the End Date field blank.</strong></p>
                            </Row>
                            <hr/>
                            <Row>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>Prospective Employee</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.longName} />
                                </Form.Group>
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.address} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.phone} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Fax Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.fax} />
                                </Form.Group>
                            </Row>
                            <div>
                                <Row>
                                    <h6>Section 1: To be Completed by the Prospective Employee</h6>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employee Name</Form.Label>
                                        <Form.Control type="text" id="employeeName1" maxLength={30} onChange={handleChange('employeeName1')} value={values.employeeName1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide employee name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Current\Previous Employer</Form.Label>
                                        <Form.Control type="text" id="currPrevEmployer1" maxLength={30} onChange={handleChange('currPrevEmployer1')} value={values.currPrevEmployer1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide current/previous employer.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Phone #</Form.Label>
                                        <PatternFormat id="employerPhone1" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerPhone1')} value={values.employerPhone1} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer phone number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Fax</Form.Label>
                                        <PatternFormat id="employerFax1" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerFax1')} value={values.employerFax1} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer fax number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer Address</Form.Label>
                                        <Form.Control type="text" id="employerStreet1" maxLength={60} onChange={handleChange('employerStreet1')} value={values.employerStreet1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid street address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer City</Form.Label>
                                        <Form.Control type="text" id="employerCity1" maxLength={30} onChange={handleChange('employerCity1')} value={values.employerCity1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer State</Form.Label>
                                        <StateDropdown name="employerAddressState1" onChange={handleChange('employerAddressState1')} value={values.employerAddressState1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please select a state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Zip Code</Form.Label>
                                        <Form.Control type="text" id="employerZip1" maxLength={5} pattern="[0-9]{5}" onChange={handleChange('employerZip1')} value={values.employerZip1} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Start Date</Form.Label>
                                        <PatternFormat id="employerStartDate1" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerStartDate1')} value={values.employerStartDate1} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>End Date</Form.Label>
                                        <PatternFormat id="employerEndDate1" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerEndDate1')} value={values.employerEndDate1} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>* = If you are currently employed, please leave the End Date field blank.</Form.Label>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Check>
                                            <Form.Check.Input id="formerEmployerAuth1" onChange={handleChange('formerEmployerAuth1')} checked={values.formerEmployerAuth1} />
                                            <Form.Check.Label>
                                                By checking this box, I hereby authorize this employer to release and forward information regarding my performance, as your current or past employee (driver). A photocopy of this release shall be as valid as the original, and may be relied upon by all persons providing information. I understand the information to be released by this employer is described below in Section 2.
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                Please acknowledge the authorization.
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Employee Signature Date</Form.Label>
                                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                                    </Form.Group>
                                </Row>
                            </div>
                            <Row>
                                <img src={CDLForm} />
                            </Row>
                            <hr/>
                            <Row>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>Prospective Employee</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.longName} />
                                </Form.Group>
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.address} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.phone} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Fax Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.fax} />
                                </Form.Group>
                            </Row>
                            <div>
                                <Row>
                                    <h6>Section 1: To be Completed by the Prospective Employee</h6>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employee Name</Form.Label>
                                        <Form.Control type="text" id="employeeName2" maxLength={30} onChange={handleChange('employeeName2')} value={values.employeeName2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide employee name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Current\Previous Employer</Form.Label>
                                        <Form.Control type="text" id="currPrevEmployer2" maxLength={30} onChange={handleChange('currPrevEmployer2')} value={values.currPrevEmployer2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide current/previous employer.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Phone #</Form.Label>
                                        <PatternFormat id="employerPhone2" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerPhone2')} value={values.employerPhone2} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer phone number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Fax</Form.Label>
                                        <PatternFormat id="employerFax2" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerFax2')} value={values.employerFax2} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer fax number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer Address</Form.Label>
                                        <Form.Control type="text" id="employerStreet2" maxLength={60} onChange={handleChange('employerStreet2')} value={values.employerStreet2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid street address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer City</Form.Label>
                                        <Form.Control type="text" id="employerCity2" maxLength={30} onChange={handleChange('employerCity2')} value={values.employerCity2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer State</Form.Label>
                                        <StateDropdown name="employerAddressState2" onChange={handleChange('employerAddressState2')} value={values.employerAddressState2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please select a state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Zip Code</Form.Label>
                                        <Form.Control type="text" id="employerZip2" maxLength={5} pattern="[0-9]{5}" onChange={handleChange('employerZip2')} value={values.employerZip2} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Start Date</Form.Label>
                                        <PatternFormat id="employerStartDate2" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerStartDate2')} value={values.employerStartDate2} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>End Date</Form.Label>
                                        <PatternFormat id="employerEndDate2" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerEndDate2')} value={values.employerEndDate2} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>* = If you are currently employed, please leave the End Date field blank.</Form.Label>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Check>
                                            <Form.Check.Input id="formerEmployerAuth2" onChange={handleChange('formerEmployerAuth2')} checked={values.formerEmployerAuth2} />
                                            <Form.Check.Label>
                                                By checking this box, I hereby authorize this employer to release and forward information regarding my performance, as your current or past employee (driver). A photocopy of this release shall be as valid as the original, and may be relied upon by all persons providing information. I understand the information to be released by this employer is described below in Section 2.
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                Please acknowledge the authorization.
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Employee Signature Date</Form.Label>
                                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                                    </Form.Group>
                                </Row>
                            </div>
                            <Row>
                                <img src={CDLForm} />
                            </Row>
                            <hr/>
                            <Row>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>Prospective Employee</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.longName} />
                                </Form.Group>
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.address} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.phone} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Fax Number</Form.Label>
                                    <Form.Control type="text" plaintext value={employer.fax} />
                                </Form.Group>
                            </Row>
                            <div>
                                <Row>
                                    <h6>Section 1: To be Completed by the Prospective Employee</h6>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employee Name</Form.Label>
                                        <Form.Control type="text" id="employeeName3" maxLength={30} onChange={handleChange('employeeName3')} value={values.employeeName3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide employee name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Current\Previous Employer</Form.Label>
                                        <Form.Control type="text" id="currPrevEmployer3" maxLength={30} onChange={handleChange('currPrevEmployer3')} value={values.currPrevEmployer3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide current/previous employer.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Phone #</Form.Label>
                                        <PatternFormat id="employerPhone3" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerPhone3')} value={values.employerPhone3} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer phone number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Fax</Form.Label>
                                        <PatternFormat id="employerFax3" format="(###) ###-####" mask="x" allowEmptyFormatting onChange={handleChange('employerFax3')} value={values.employerFax3} disabled={readonly} className="form-control" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid employer fax number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer Address</Form.Label>
                                        <Form.Control type="text" id="employerStreet3" maxLength={60} onChange={handleChange('employerStreet3')} value={values.employerStreet3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid street address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Employer City</Form.Label>
                                        <Form.Control type="text" id="employerCity3" maxLength={30} onChange={handleChange('employerCity3')} value={values.employerCity3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer State</Form.Label>
                                        <StateDropdown name="employerAddressState3" onChange={handleChange('employerAddressState3')} value={values.employerAddressState3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please select a state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Employer Zip Code</Form.Label>
                                        <Form.Control type="text" id="employerZip3" maxLength={5} pattern="[0-9]{5}" onChange={handleChange('employerZip3')} value={values.employerZip3} disabled={readonly} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Start Date</Form.Label>
                                        <PatternFormat id="employerStartDate3" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerStartDate3')} value={values.employerStartDate3} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>End Date</Form.Label>
                                        <PatternFormat id="employerEndDate3" format="##/##/####" mask="x" allowEmptyFormatting onChange={handleChange('employerEndDate3')} value={values.employerEndDate3} disabled={readonly} className="form-control" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>* = If you are currently employed, please leave the End Date field blank.</Form.Label>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Check>
                                            <Form.Check.Input id="formerEmployerAuth3" onChange={handleChange('formerEmployerAuth3')} checked={values.formerEmployerAuth3} />
                                            <Form.Check.Label>
                                                By checking this box, I hereby authorize this employer to release and forward information regarding my performance, as your current or past employee (driver). A photocopy of this release shall be as valid as the original, and may be relied upon by all persons providing information. I understand the information to be released by this employer is described below in Section 2.
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                Please acknowledge the authorization.
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Employee Signature Date</Form.Label>
                                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                                    </Form.Group>
                                </Row>
                            </div>
                            <Row>
                                <img src={CDLForm} />
                            </Row>
                        </>
                        )}
                        </>
                )}


                <Row>
                    <hr/>
                    <strong>I certify that the information contained in my application is true and complete. I understand that false statements or material omissions on this application may result in rejection of my application, or if employed, may result in my dismissal.</strong>
                </Row>
                <Row>
                    <Form.Group as={Col} md="4">
                        <Form.Label></Form.Label>
                        <Form.Check>
                            <Form.Check.Input required id="consent" onChange={handleChange('appAck')} checked={values.appAck} disabled={readonly} />
                            <Form.Check.Label>
                                I have read and understand the above
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="5">
                        <Form.Label>Signature:</Form.Label>
                        <Form.Control type="text" id="signature" maxLength={60} required onChange={handleChange('appESig')} value={values.appESig} disabled={readonly} />
                        <Form.Control.Feedback type="invalid">
                            Please provide an electronic signature.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                {buttons}
            </Form>
        </div>
    );
}
