/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function ElectronicSignature({ previous, next, handleChange, values, employerName }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return(
        <div className="container-lg personal-info">
        
            <h6 className="middle strong">Electronic Signature Consent</h6>
            <p>
                As part of the hiring process at {employerName}, the "Company," you will need to consent to a background check electronically. By typing your name and clicking in the box below, you are consenting to receive any communications (legally required or otherwise) and all changes to such communications electronically. In order to use the website, you must provide at your own expense an Internet connected device that is compatible with the minimum requirements outlined below. You also confirm that your device will meet these specifications and requirements and will permit you to access and retain the communications electronically each time you access and use the website.
            </p>
            <h5>System Requirements to Access Information</h5>
            <p>
                To receive and view an electronic copy of the Communications you must have the following equipment and software:

                <ul>
                    <li>A personal computer or other device which is capable of accessing the Internet. Your access to this page verifies that your system/device meets these requirements.</li>
                    <li>An Internet web browser which is capable of supporting 128-bit SSL encrypted communications, JavaScript, and cookies. Your system or device must have 128-bit SSL encryption software. Your access to this page verifies that your browser and encryption software/device meet these requirements.</li>
                </ul>
            </p>

            <h5>System Requirements to Retain Information</h5>
            
            <p>
                To retain a copy, you must either have a printer connected to your personal computer or other device or, alternatively, the ability to save a copy through use of printing service or software such as Adobe Acrobat. If you would like to proceed using paper forms, please choose option 2 below.
            </p>

            <h5>Withdrawal of Electronic Acceptance of Disclosures and Notices</h5>
            <p>
                If you do not wish to review and sign the forms electronically, you may contact InCheck, Inc. to receive a paper copy of the subsequent forms and provide a wet signature. To do so, please contact InCheck, Inc. at intouch@inchecksolutions.com or by phone at (414) 727-1718.
            </p>

            <p>
                To ensure that a signature is unique and to safeguard you against unauthorized use of your name, your IP address has been recorded and will be stored along with your electronic signature. Please note that if you wish to submit your Disclosure and Authorization Forms electronically, InCheck, Inc. requires that you include your social security number or user identification. All of your information will be sent over our secure website using 256-bit SSL encryption.
            </p>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row></Row>
                <Row>
                    <h4>CHOOSE ONE OF THE FOLLOWING OPTIONS:</h4>
                    <hr/>
                    <Form.Group as={Col} md="12">
                        <h5>Option 1</h5>
                        
                        <Form.Check>
                            <Form.Check.Input required name="e-sign-consent" id="e-sig-consent-no" type='radio' onChange={handleChange('eSigConsent')} checked={values.eSigConsent === 'Y'} value="Y" />
                            <Form.Check.Label>
                                I consent to transacting electronically, including receiving legally required notices electronically. I understand that InCheck, Inc. uses 256-bit SSL encryption to ensure that my signed documents are not altered after submission. I agree to allow InCheck, Inc. to validate my signed documents in this way.
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <h5>Option 2</h5>
                        <Form.Check>
                            <Form.Check.Input required name="e-sign-consent" id="e-sig-consent-no" type='radio' onChange={handleChange('eSigConsent')} checked={values.eSigConsent === 'N'} value="N" />
                            <Form.Check.Label>
                                I do not wish to electronically sign these forms. Rather, I prefer to have paper copies sent to me, sign them by hand, and return them by mail or in person to {employerName}. I understand that obtaining the paper copy is my responsibility. If you select this option, do not proceed any further and contact InCheck immediately.' 
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <hr/>

                    <Form.Group as={Col} md="6">
                        <Form.Label>Enter first and last name:</Form.Label>
                        <Form.Control type="text" id="signature" maxLength={60} required onChange={handleChange('eSignature')} value={values.eSignature} />
                        <Form.Control.Feedback type="invalid">
                            Please provide an electronic signature.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" plaintext readOnly defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Next</Button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    );
};
