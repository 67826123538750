/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StateDropdown from "./components/state-dropdown";
import {PatternFormat} from "react-number-format";

export default function BackgroundInfoDisclosure({ previous, next, handleChange, values }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    const subSectionText = {
        dhsBidCriminalChargesPending: 'If Yes, list each charge, when it occurred or the date of the charge, and the city and state where the court is located. You may be asked to supply additional information, including a copy of the criminal complaint or any other relevant court or police documents.',
        dhsBidAdjudDelinquent: 'If Yes, list each crime, when it occurred or the date of the conviction, and the city and state where the court is located. You may be asked to supply additional information including a certified copy of the judgment of conviction, a copy of the criminal complaint, or any other relevant court or police documents.',
        dhsBidChildAbusedOrNeglected: 'Provide an explanation below, including when and where the incident(s) occurred.',
        dhsBidAdultAbusedOrNeglected: 'If Yes, explain, including when and where it happened.',
        dhsBidMissappProperty: 'If Yes, explain, including when and where it happened.',
        dhsBidAbusedElderly: 'If Yes, explain, including when and where it happened.',
        dhsBidNonCurrentCredential: 'If Yes, explain, including credential name, limitations or restrictions, and time period.',
        dhsBidCredentialsLimited: 'If Yes, explain, including when and where it happened.',
        dhsBidDeniedLiveOnPremises: 'If Yes, explain, including when and where it happened and the reason.',
        dhsBidDischargedArmedForces: '',
        dhsBidResidedOutsideWILast3: 'If Yes, list each state and the dates you resided there.',
        dhsBidResidedOutsideWILast7: 'If Yes, list each state and the dates you resided there.',
        dhsBidCaregiverBgCheckLast4: 'If Yes, list the date of each check, and the name, address, and phone number of the person, facility, or government agency that conducted each check.',
        dhsBidReqRehabReview: 'If Yes, list the review date and the review result. You may be asked to provide a copy of the review decision.'
    };

    const generateSubsection = function(name) {
        const subField = name + 'Details';
        return values[name] === 'Y' ? <><Row>
            <Form.Group as={Col} md="10">
                <Form.Label>{subSectionText[name]}</Form.Label>
                <Form.Control as="textarea" id={name} rows={3} maxLength={500} onChange={handleChange(subField)} value={values[subField]} required />
            </Form.Group>
        </Row><hr/></> : <><hr/></>;
    }

    const generateArmedForcesSubSection = function(name) {
        return values[name] === 'Y'
            ? <>
                <Row>
                    <Form.Group as={Col} md="3">
                        If <b>Yes</b>, indicate the year of discharge
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Control type="text" id="dhsBidAFDischargeYear" maxLength={4} onChange={handleChange('dhsBidAFDischargeYear')} value={values.dhsBidAFDischargeYear} required />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="10">
                        If you were discharged within the last three years of today's date, you will be given instructions on how to provide a copy of your DD214.
                    </Form.Group>
                </Row>
                <hr/>
              </>
            : <><hr/></>;
    }

    return <div className="container-lg personal-info">

        <h6 className="strong middle">BACKGROUND INFORMATION DISCLOSURE (BID)</h6>
        <h6 className="strong middle">FOR ENTITY EMPLOYEES AND CONTRACTORS</h6>
        <ul>
            <li><strong>PENALTY:</strong> A person who provides false information on this form may be subject to
                forfeiture and sanctions as provided in Wis. Stat. § 50.065 (6)(c) and Wis. Admin Code § DHS 12.05(4)
            </li>
            <li>Completion of this form to verify your eligibility for employment/service as a "caregiver" is required
                by Wis.Stat § 50.065 and Wis. Admin Code ch. DHS 12. Failure to complete this form may result in denial
                or termination of your employment, contract, or service agreement
            </li>
        </ul>
        <p>Refer to DQA form <a href="https://www.dhs.wisconsin.gov/forms/f8/f82064a.pdf"
                                target="_blank">F-82064A, <i>Instructions</i></a>, for additional information.</p>
        <hr/>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Form.Group as={Col} md="12">
                    <Form.Label className='check-label'>Check the box that applies to you.</Form.Label><br/>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Check>
                        <Form.Check.Input type="radio" name="applicantRole" id="applicantRoleE" required
                                          onChange={handleChange('dhsBidApplicantRole')}
                                          checked={values.dhsBidApplicantRole === "E"} value="E"/>
                        <Form.Check.Label>Applicant / Employee</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Check>
                        <Form.Check.Input type="radio" name="applicantRole" id="applicantRoleH"
                                          onChange={handleChange('dhsBidApplicantRole')}
                                          checked={values.dhsBidApplicantRole === "H"} value="H"/>
                        <Form.Check.Label>Student / Volunteer</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Check>
                        <Form.Check.Input type="radio" name="applicantRole" id="applicantRoleA" required
                                          onChange={handleChange('dhsBidApplicantRole')}
                                          checked={values.dhsBidApplicantRole === "A"} value="A"/>
                        <Form.Check.Label>Contractor</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check>
                        <Form.Check.Input type="radio" name="applicantRole" id="applicantRoleO"
                                          onChange={handleChange('dhsBidApplicantRole')}
                                          checked={values.dhsBidApplicantRole === "O"} value="O"/>
                        <Form.Check.Label>Other – Specify:</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Control inline type="text" onChange={handleChange('dhsBidApplicantRoleOther')} value={values.dhsBidApplicantRoleOther} required={values.dhsBidApplicantRole === "O"} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="12">
                    <p><strong>NOTE:</strong> This form should NOT be used by applicants for entity operator approval
                        (license, certification, registration or other DHS approval) or by entities requesting approval
                        for
                        an individual to reside in entity facilities as a non-client resident. Applicants for entity
                        operator approval or for a non-client resident background check must request an <a
                            href="https://health.wisconsin.gov/dqaPortal/public/applicantSearch.html"
                            target="_blank"><i>entity</i> background check</a> from the Division of Quality Assurance.
                    </p>
                    <hr/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="4">
                    <Form.Label>Full Legal Name - <i>First</i></Form.Label>
                    <Form.Control required maxLength={30} onChange={handleChange('dhsBidFirstName')} value={values.dhsBidFirstName} />
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label><i>Middle</i></Form.Label>
                    <Form.Control maxLength={30} onChange={handleChange('dhsBidMiddleName')} value={values.dhsBidMiddleName}/>
                </Form.Group>
                <Form.Group as={Col} md="5">
                    <Form.Label><i>Last</i></Form.Label>
                    <Form.Control required maxLength={30} onChange={handleChange('dhsBidLastName')} value={values.dhsBidLastName}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="12">
                    <Form.Label>Other Names (including prior to marriage)</Form.Label>
                    <Form.Control maxLength={100} onChange={handleChange('dhsBidOtherNames')} value={values.dhsBidOtherNames}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="7">
                    <Form.Label>Position Title (applied for or existing)</Form.Label>
                    <Form.Control required maxLength={50} onChange={handleChange('dhsBidPosition')} value={values.dhsBidPosition}/>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>Birth Date <i>(MM/DD/YYYY)</i></Form.Label>
                    <PatternFormat id="dateOfBirth" format="##/##/####" required onChange={handleChange('dhsBidBirthDate')} value={values.dhsBidBirthDate} className="form-control" />
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>Sex</Form.Label><br/>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="dhsBidSex" id="dhsBidSexM" required
                                          onChange={handleChange('dhsBidSex')}
                                          checked={values.dhsBidSex === "M"} value="M"/>
                        <Form.Check.Label>M</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="dhsBidSex" id="dhsBidSexF"
                                          onChange={handleChange('dhsBidSex')}
                                          checked={values.dhsBidSex === "F"} value="F"/>
                        <Form.Check.Label>F</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="5">
                    <Form.Label>Home Address</Form.Label>
                    <Form.Control required maxLength={60} onChange={handleChange('dhsBidAddressStreet')} value={values.dhsBidAddressStreet}/>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>City</Form.Label>
                    <Form.Control required maxLength={30} onChange={handleChange('dhsBidAddressCity')} value={values.dhsBidAddressCity}/>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>State</Form.Label>
                    <StateDropdown name="dhsBidAddressState" onChange={handleChange('dhsBidAddressState')}
                                   value={values.dhsBidAddressState} required={true}/>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>ZIP</Form.Label>
                    <Form.Control required maxLength={5} pattern="[0-9]{5}" onChange={handleChange('dhsBidAddressZip')} value={values.dhsBidAddressZip}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="9">
                    <Form.Label>Business Name and Address - Employer (Entity)</Form.Label><br/>
                    <Form.Control type="text" readonly plaintext
                                  defaultValue="ProHealth Care, 721 American Ave, Waukesha, Wisconsin 53188"/>
                </Form.Group>
            </Row>
            <Row>
                <p>
                    <strong>Answering "NO" to all questions does not guarantee employment, a contract, or service
                        agreement.</strong><br/>
                </p>
            </Row>
            <Row>
                <Form.Group as={Col} md="12">
                    <strong>SECTION A - DISCLOSURES</strong>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="10">
                    1. Do you have any criminal charges pending against you, including in federal, state, local,
                    military, and tribal courts?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="criminalChargesPending" id="criminalChargesPending"
                                          required onChange={handleChange('dhsBidCriminalChargesPending')}
                                          checked={values.dhsBidCriminalChargesPending === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="criminalChargesPending" id="criminalChargesPending"
                                          onChange={handleChange('dhsBidCriminalChargesPending')}
                                          checked={values.dhsBidCriminalChargesPending === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidCriminalChargesPending')}
            <Row>
                <Form.Group as={Col} md="10">
                    2. Were you ever convicted of any crime anywhere, including in federal, state, local, military, and
                    tribal courts?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="adjudDelinquent" id="adjudDelinquent" required
                                          onChange={handleChange('dhsBidAdjudDelinquent')}
                                          checked={values.dhsBidAdjudDelinquent === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="adjudDelinquent" id="adjudDelinquent"
                                          onChange={handleChange('dhsBidAdjudDelinquent')}
                                          checked={values.dhsBidAdjudDelinquent === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidAdjudDelinquent')}
            <Row>
                <Form.Group as={Col} md="10">
                    Please note that Wis. Stat. § 48.981, <i>Abused or neglected children and abused unborn
                    children,</i> may apply to information concerning findings of child abuse and neglect.<br/><br/>
                    3. Has any government or regulatory agency (other than the police) ever found that you committed child
                    abuse or neglect?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="childAbusedOrNeglected" id="childAbusedOrNeglected"
                                          required onChange={handleChange('dhsBidChildAbusedOrNeglected')}
                                          checked={values.dhsBidChildAbusedOrNeglected === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="childAbusedOrNeglected" id="childAbusedOrNeglected"
                                          onChange={handleChange('dhsBidChildAbusedOrNeglected')}
                                          checked={values.dhsBidChildAbusedOrNeglected === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidChildAbusedOrNeglected')}
            <Row>
                <Form.Group as={Col} md="10">
                    4. Has any government or regulatory agency (other than the police) ever found that you abused or
                    neglected <strong>any person or client</strong>?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="adultAbusedOrNeglected" id="adultAbusedOrNeglected"
                                          required onChange={handleChange('dhsBidAdultAbusedOrNeglected')}
                                          checked={values.dhsBidAdultAbusedOrNeglected === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="adultAbusedOrNeglected" id="adultAbusedOrNeglected"
                                          onChange={handleChange('dhsBidAdultAbusedOrNeglected')}
                                          checked={values.dhsBidAdultAbusedOrNeglected === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidAdultAbusedOrNeglected')}
            <Row>
                <Form.Group as={Col} md="10">
                    5. Has any government or regulatory agency (other than the police) ever found that you
                    misappropriated (improperly took or used) the property of a person or client?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="missappProperty" id="missappProperty" required
                                          onChange={handleChange('dhsBidMissappProperty')}
                                          checked={values.dhsBidMissappProperty === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="missappProperty" id="missappProperty"
                                          onChange={handleChange('dhsBidMissappProperty')}
                                          checked={values.dhsBidMissappProperty === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidMissappProperty')}
            <Row>
                <Form.Group as={Col} md="10">
                    6. Has any government or regulatory agency (other than the police) ever found that you abused
                    an <strong>elderly person</strong>?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="abusedElderly" id="abusedElderly" required
                                          onChange={handleChange('dhsBidAbusedElderly')}
                                          checked={values.dhsBidAbusedElderly === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="abusedElderly" id="abusedElderly"
                                          onChange={handleChange('dhsBidAbusedElderly')}
                                          checked={values.dhsBidAbusedElderly === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidAbusedElderly')}
            <Row>
                <Form.Group as={Col} md="10">
                    7. Do you have a government issued credential that is not current or is limited so as to restrict
                    you from providing care to clients?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="nonCurrentCredential" id="nonCurrentCredential" required
                                          onChange={handleChange('dhsBidNonCurrentCredential')}
                                          checked={values.dhsBidNonCurrentCredential === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="nonCurrentCredential" id="nonCurrentCredential"
                                          onChange={handleChange('dhsBidNonCurrentCredential')}
                                          checked={values.dhsBidNonCurrentCredential === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidNonCurrentCredential')}
            <Row></Row>

            <Row>
                <Form.Group as={Col} md="12">
                    <strong>SECTION B - OTHER REQUIRED INFORMATION</strong>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="10">
                    1. Has any government or regulatory agency ever limited, denied, or revoked your license,
                    certification, or registration to provide care, treatment, or educational services?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="credentialsLimited" id="credentialsLimited" required
                                          onChange={handleChange('dhsBidCredentialsLimited')}
                                          checked={values.dhsBidCredentialsLimited === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="credentialsLimited" id="credentialsLimited"
                                          onChange={handleChange('dhsBidCredentialsLimited')}
                                          checked={values.dhsBidCredentialsLimited === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidCredentialsLimited')}
            <Row>
                <Form.Group as={Col} md="10">
                    2. Has any government or regulatory agency ever denied you permission or restricted your ability to
                    live on the premises of a care providing facility?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="deniedLiveOnPremises" id="deniedLiveOnPremises" required
                                          onChange={handleChange('dhsBidDeniedLiveOnPremises')}
                                          checked={values.dhsBidDeniedLiveOnPremises === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="deniedLiveOnPremises" id="deniedLiveOnPremises"
                                          onChange={handleChange('dhsBidDeniedLiveOnPremises')}
                                          checked={values.dhsBidDeniedLiveOnPremises === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidDeniedLiveOnPremises')}
            <Row>
                <Form.Group as={Col} md="10">
                    3. Have you been discharged from a branch of the US Armed Forces, including any reserve component?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="dischargedArmedForces" id="dischargedArmedForces" required
                                          onChange={handleChange('dhsBidDischargedArmedForces')}
                                          checked={values.dhsBidDischargedArmedForces === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="dischargedArmedForces" id="dischargedArmedForces"
                                          onChange={handleChange('dhsBidDischargedArmedForces')}
                                          checked={values.dhsBidDischargedArmedForces === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateArmedForcesSubSection('dhsBidDischargedArmedForces')}
            <Row>
                <Form.Group as={Col} md="10">
                    4. Have you resided outside of Wisconsin in the last three (3) years?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="residedOutsideWILast3" id="residedOutsideWILast3" required
                                          onChange={handleChange('dhsBidResidedOutsideWILast3')}
                                          checked={values.dhsBidResidedOutsideWILast3 === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="residedOutsideWILast3" id="residedOutsideWILast3"
                                          onChange={handleChange('dhsBidResidedOutsideWILast3')}
                                          checked={values.dhsBidResidedOutsideWILast3 === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidResidedOutsideWILast3')}
            <Row>
                <Form.Group as={Col} md="10">
                    5. If you are employed by or applying for the State of Wisconsin, have you resided outside of
                    Wisconsin in the last seven (7) years?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="residedOutsideWILast7" id="residedOutsideWILast7" required
                                          onChange={handleChange('dhsBidResidedOutsideWILast7')}
                                          checked={values.dhsBidResidedOutsideWILast7 === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="residedOutsideWILast7" id="residedOutsideWILast7"
                                          onChange={handleChange('dhsBidResidedOutsideWILast7')}
                                          checked={values.dhsBidResidedOutsideWILast7 === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidResidedOutsideWILast7')}
            <Row>
                <Form.Group as={Col} md="10">
                    6. Have you had a caregiver background check done within the last four (4) years?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="caregiverBgCheckLast4" id="caregiverBgCheckLast4" required
                                          onChange={handleChange('dhsBidCaregiverBgCheckLast4')}
                                          checked={values.dhsBidCaregiverBgCheckLast4 === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="caregiverBgCheckLast4" id="caregiverBgCheckLast4"
                                          onChange={handleChange('dhsBidCaregiverBgCheckLast4')}
                                          checked={values.dhsBidCaregiverBgCheckLast4 === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidCaregiverBgCheckLast4')}
            <Row>
                <Form.Group as={Col} md="10">
                    7. Have you ever requested a rehabilitation review with the Wisconsin Department of Health Services,
                    a county department, a private child placing agency, school board, or DHS-designated tribe?
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="reqRehabReview" id="reqRehabReview" required
                                          onChange={handleChange('dhsBidReqRehabReview')}
                                          checked={values.dhsBidReqRehabReview === "Y"} value="Y"/>
                        <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline>
                        <Form.Check.Input type="radio" name="reqRehabReview" id="reqRehabReview"
                                          onChange={handleChange('dhsBidReqRehabReview')}
                                          checked={values.dhsBidReqRehabReview === "N"} value="N"/>
                        <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
            </Row>
            {generateSubsection('dhsBidReqRehabReview')}
            <Row><strong><i>Read and initial the following statement</i></strong></Row>
            <Row>
                <Form.Group as={Col} md="1">
                    <Form.Control type="text" id="dhsBidInitials" maxLength={5} required
                                  onChange={handleChange('dhsBidInitials')} value={values.dhsBidInitials}/>
                    <Form.Control.Feedback type="invalid">
                        Please initial.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="9">I have completed and reviewed this form (F-82064, BID) and affirm that the information is
                    true and correct as of today's date.</Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>NAME - Person Completing This Form</Form.Label>
                    <Form.Control type="text" id="signature" maxLength={60} required
                                  onChange={handleChange('dhsBidESig')} value={values.dhsBidESig}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide an electronic signature.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Date Submitted</Form.Label>
                    <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="12">
                    <Button onClick={previous} className="btn-secondary">Back</Button>
                    <Button type="submit">Next</Button>
                </Form.Group>
            </Row>
        </Form>
    </div>;
}
