import React from "react";

import { EmployerInfo } from "./App";
import Application from './Application';
import BackgroundDisclosure from './BackgroundDisclosure';
import BackgroundInfoDisclosure from "./BackgroundInfoDisclosure";
import BGCheckAuthorization from './BGCheckAuthorization';
import ElectronicSignature from './ElectronicSignature';
import Home from './Home';
import InvestigativeConsumerReport from './InvestigativeConsumerReport';
import Notices from "./Notices";
import PersonalInfo from "./PersonalInfo";
import PreEmploymentScreening from './PreEmploymentScreening';
import ProHealthApplication from "./prohealth/ProHealthApplication";
import ProHealthBackgroundDisclosure from './prohealth/ProHealthBackgroundDisclosure';
import ProHealthBGCheckAuthorization from './prohealth/ProHealthBGCheckAuthorization';
import ProHealthInvestigativeConsumerReport from './prohealth/ProHealthInvestigativeConsumerReport';
import ProHealthPersonalInfo from "./prohealth/ProHealthPersonalInfo";
import RightsSummary from './RightsSummary';
import Success from './Success';

export interface WorkflowProps {
    readonly clientId: string;
    readonly workflowId?: string;
    readonly hiringManagerId?: string;
    readonly employer: any;
    readonly volunteer?: boolean;
}

export interface InitialState {
    page: number,
    employer: EmployerInfo,
    employerName: string,
    clientId: string,
    eSignatureDate: string,
    workflowId?: string | undefined,
    hiringManagerId?: string | undefined,
    volunteer?: boolean | undefined
}

export default class Workflow extends React.Component<WorkflowProps, { page: number, employer: EmployerInfo, employerName: string, clientId: string, workflowId?: string, hiringManagerId?: string, eSignatureDate: string, volunteer?: boolean }> {

    constructor(props: WorkflowProps) {
        super(props);
        var initialState : InitialState = {
            page: 0,
            employer: props.employer,
            employerName: props.employer.longName,
            clientId: props.clientId,
            eSignatureDate: new Date().toLocaleDateString()
          };

        if (props.workflowId) {
          initialState['workflowId'] = props.workflowId;
        }

        if (props.hiringManagerId) {
            initialState['hiringManagerId'] = props.hiringManagerId;
        }

        if (props.volunteer) {
            initialState['volunteer'] = props.volunteer;
        }

        this.state = {...initialState};
    }

    previous = () => {
        const {page} = this.state;
        this.setState({ page: page - 1 });
    };

    next = () => {
        const { page } = this.state;
        this.setState({ page: page + 1 });
    };

    handleChange = (input: any) => (e: any) => {
        if (e.target.type === "checkbox") {
            this.setState({
                ...this.state,
                [input]: e.target.checked
            });
        } else {
            this.setState({
                ...this.state,
                [input]: e.target.value
            });
        }
    };

    clearState = () => {
        this.setState({
            page: 0
        });
    };

    render(): any {
        const values = this.state;

        if (values.clientId === 'alm') {
            switch(values.page) {
                case 1:     return(<PersonalInfo next={this.next} handleChange={this.handleChange} values={values} />);
                case 2:     return(<ElectronicSignature previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 3:     return(<BackgroundDisclosure previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 4:     return(<RightsSummary previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} />);
                case 5:     return(<PreEmploymentScreening previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 6:     return(<Notices previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 7:     return(<InvestigativeConsumerReport previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 8:     return(<BGCheckAuthorization previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 9:     return(<Application previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employer={this.state.employer} />);
                case 10:    return(<Success clearState={this.clearState} />);
                default:    return(<Home next={this.next} employerName={this.state.employerName} />);
            }
        }
        else if (values.clientId === 'prohealthcare') {
            switch(values.page) {
                case 1:     return(<ProHealthPersonalInfo next={this.next} handleChange={this.handleChange} values={values} />);
                case 2:     return(<ElectronicSignature previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 3:     return(<ProHealthBackgroundDisclosure previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 4:     return(<RightsSummary previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} />);
                case 5:     return(<Notices previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 6:     return(<ProHealthInvestigativeConsumerReport previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} employerName={this.state.employerName} />);
                case 7:     return(<ProHealthBGCheckAuthorization previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} />);
                case 8:     return(<BackgroundInfoDisclosure previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} />);
                case 9:     return(<ProHealthApplication previous={this.previous} next={this.next} handleChange={this.handleChange} values={values} />);
                case 10:    return(<Success clearState={this.clearState} />);
                default:    return(<Home next={this.next} employerName={this.state.employerName} />);
            }
        }
    }
}
