/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function BGCheckAuthorization({ previous, next, handleChange, values }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return(
        <div className="container-lg">
            <h6 className="strong middle">ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND CHECK</h6>
            <p className="middle">[IMPORTANT -- PLEASE READ CAREFULLY BEFORE SIGNING ACKNOWLEDGMENT]</p>

            <p>I acknowledge receipt of the separate documents entitled DISCLOSURE REGARDING BACKGROUND INVESTIGATION, A SUMMARY OF YOUR RIGHTS
                UNDER THE FAIR CREDIT REPORTING ACT and the DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT and certify that I have read and understand
                those documents. I hereby authorize the procurement of "consumer reports" and/or "investigative consumer reports" by the Company at
                any time after receipt of this authorization and throughout my employment (including independent contractor or volunteer assignments),
                if applicable. To this end, I hereby authorize, without reservation, any law enforcement agency, administrator, state or federal agency,
                institution, school or university (public or private), information service bureau, employer, or insurance company to furnish any and
                all background information requested by InCheck, Inc., Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510, 7500 W State Street,
                Suite 200, Wauwatosa, WI 53213, https://www.inchecksolutions.com/privacy-policy/ and/or Company itself. I agree that a facsimile ("fax"),
                electronic or photographic copy of this Authorization shall be as valid as the original.</p>

            <p>I understand that by checking the <b>"I AGREE"</b> box, and clicking on the <b>"NEXT"</b> button below, constitutes my
                electronic signature, dated as of when I click on the <b>"NEXT"</b> button, and that by doing so:</p>

            <ul>
                <li>I am authorizing InCheck, Inc. and ProHealth Care to conduct the background check(s) described above</li>
                <li>I am consenting to use electronic means to sign this form and have read and understand the above disclosure</li>
                <li>I acknowledge I may request a hard copy of this Disclosure and Authorization form after agreeing to the background check
                    electronically by calling InCheck, Inc. at Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510.</li>
            </ul>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col} md="2">
                        <Form.Label></Form.Label>
                        <Form.Check>
                            <Form.Check.Input required id="consent" onChange={handleChange('bgCheckAuthorizationAck')} checked={values.bgCheckAuthorizationAck} />
                            <Form.Check.Label>
                                I agree
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Enter first and last name:</Form.Label>
                        <Form.Control type="text" id="signature" maxLength={60} required onChange={handleChange('bgCheckAuthorizationESig')} value={values.bgCheckAuthorizationESig} />
                        <Form.Control.Feedback type="invalid">
                            Please provide an electronic signature.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Next</Button>
                    </Form.Group>
                </Row>
            </Form>

        </div>
    );
};
