/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function RightsSummary({ previous, next, handleChange, values }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return (
        <div className="container-lg">
            <h6 className="strong middle">Para informaci&oacute;n en español, visite <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a> o escribe a la Consumer Financial Protection Bureau, 1700 G Street NW, Washington, DC 20552.</h6>
            <p className="middle">A Summary of Your Rights Under the Fair Credit Reporting Act</p>

            <p>The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and privacy of information in the files of consumer reporting agencies.  There are many types of consumer reporting agencies, including credit bureaus and specialty agencies (such as agencies that sell information about check writing histories, medical records, and rental history records).  Here is a summary of your major rights under FCRA.  <b>For more information, including information about additional rights, go to <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a> or write to: Consumer Financial Protection Bureau, 1700 G Street NW, Washington, DC 20552.</b></p>

            <ul>
                <li><b>You must be told if information in your file has been used against you.</b>  Anyone who uses a credit report or another type of consumer report to deny your application for credit, insurance, or employment – or to take another adverse action against you – must tell you, and must give you the name, address, and phone number of the agency that provided the information.</li>
                <li><b>You have the right to know what is in your file.</b>  You may request and obtain all the information about you in the files of a consumer reporting agency (your “file disclosure”).  You will be required to provide proper identification, which may include your Social Security number.  In many cases, the disclosure will be free.  You are entitled to a free file disclosure if:
                    <ul>
                        <li>a person has taken adverse action against you because of information in your credit report;</li>
                        <li>you are the victim of identity theft and place a fraud alert in your file;</li>
                        <li>your file contains inaccurate information as a result of fraud;</li>
                        <li>you are on public assistance;</li>
                        <li>you are unemployed but expect to apply for employment within 60 days.</li>
                    </ul>
                </li>
            </ul>

            <p>In addition, all consumers are entitled to one free disclosure every 12 months upon request from each nationwide credit bureau and from nationwide specialty consumer reporting agencies.  See <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a> for additional information.</p>

            <ul>
                <li><b>You have the right to ask for a credit score.</b> Credit scores are numerical summaries of your credit-worthiness based on information from credit bureaus.  You may request a credit score from consumer reporting agencies that create scores or distribute scores used in residential real property loans, but you will have to pay for it.  In some mortgage transactions, you will receive credit score information for free from the mortgage lender.</li>
                <li><b>You have the right to dispute incomplete or inaccurate information.</b>  If you identify information in your file that is incomplete or inaccurate, and report it to the consumer reporting agency, the agency must investigate unless your dispute is frivolous.  See <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a> for an explanation of dispute procedures.</li>
                <li><b>Consumer reporting agencies must correct or delete inaccurate, incomplete, or unverifiable information.</b>  Inaccurate, incomplete, or unverifiable information must be removed or corrected, usually within 30 days.  However, a consumer reporting agency may continue to report information it has verified as accurate.</li>
                <li><b>Consumer reporting agencies may not report outdated negative information.</b>  In most cases, a consumer reporting agency may not report negative information that is more than seven years old, or bankruptcies that are more than 10 years old.</li>
                <li><b>Access to your file is limited.</b>  A consumer reporting agency may provide information about you only to people with a valid need – usually to consider an application with a creditor, insurer, employer, landlord, or other business.  The FCRA specifies those with a valid need for access.</li>
                <li><b>You must give your consent for reports to be provided to employers.</b>  A consumer reporting agency may not give out information about you to your employer, or a potential employer, without your written consent given to the employer.  Written consent generally is not required in the trucking industry.  For more information, go to <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a>.</li>
                <li><b>You may limit "prescreened” offers of credit and insurance you get based on information in your credit report.</b>  Unsolicited "prescreened” offers for credit and insurance must include a toll-free phone number you can call if you choose to remove your name and address from the lists these offers are based on.  You may opt out with the nationwide credit bureaus at 1-888-567-8688.</li>
                <li><p>The following FCRA right applies with respect to nationwide consumer reporting agencies:</p>
                    <p><b>Consumers Have the Right To Obtain a Security Freeze</b></p>
                    <p><b>You have a right to place a "security freeze” on your credit report, which will prohibit a consumer reporting agency from releasing information in your credit report without your express authorization.</b>  The security freeze is designed to prevent credit, loans, and services from being approved in your name without your consent.  However, you should be aware that using a security freeze to take control over who gets access to the personal and financial information in your credit report may delay, interfere with, or prohibit the timely approval of any subsequent request or application you make regarding a new loan, credit, mortgage, or any other account involving the extension of credit.</p>
                    <p>As an alternative to a security freeze, you have the right to place an initial or extended fraud alert on your credit file at no cost.  An initial fraud alert is a 1-year alert that is placed on a consumer's credit file.  Upon seeing a fraud alert display on a consumer's credit file, a business is required to take steps to verify the consumer's identity before extending new credit.  If you are a victim of identity theft, you are entitled to an extended fraud alert, which is a fraud alert lasting 7 years.</p>
                    <p>A security freeze does not apply to a person or entity, or its affiliates, or collection agencies acting on behalf of the person or entity, with which you have an existing account that requests information in your credit report for the purposes of reviewing or collecting the account.  Reviewing the account includes activities related to account maintenance, monitoring, credit line increases, and account upgrades and enhancements.</p>
                </li>
                <li><p><b>You may seek damages from violators.</b>  If a consumer reporting agency, or, in some cases, a user of consumer reports or a furnisher of information to a consumer reporting agency violates the FCRA, you may be able to sue in state or federal court.</p></li>
                <li><p><b>Identity theft victims and active duty military personnel have additional rights.</b>  For more information, visit <a href="www.consumerfinance.gov/learnmore">www.consumerfinance.gov/learnmore</a>.</p></li>
            </ul>

            <p><b>States may enforce the FCRA, and many states have their own consumer reporting laws.  In some cases, you may have more rights under state law.  For more information, contact your state or local consumer protection agency or your state Attorney General.  For information about your federal rights, contact:</b></p>

            <table className='table'>
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>TYPE OF BUSINESS:</th>
                    <th>CONTACT:</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td rowSpan={2}>1.</td>
                    <td>a.</td>
                    <td>Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates</td>
                    <td>a. Consumer Financial Protection Bureau<br/>
                        1700 G Street NW<br/>
                        Washington, DC 20552
                    </td>
                </tr>
                <tr>
                    <td>b.</td>
                    <td>Such affiliates that are not banks, savings associations, or credit unions also should list, in addition to the CFPB:</td>
                    <td>b. Federal Trade Commission<br/>
                        Consumer Response Center<br/>
                        600 Pennsylvania Avenue NW<br/>
                        Washington, DC 20580<br/>
                        (877) 382-4357
                    </td>
                </tr>
                <tr>
                    <td rowSpan={5}>2.</td>
                    <td colSpan={3}>To the extent not included in item 1 above:</td>
                </tr>
                <tr>
                    <td>a.</td>
                    <td>National banks, federal savings associations, and federal branches and federal agencies of foreign banks</td>
                    <td>a. Office of the Comptroller of the Currency<br/>
                        Customer Assistance Group<br/>
                        P.O. Box 53570<br/>
                        Houston, TX 77052
                    </td>
                </tr>
                <tr>
                    <td>b.</td>
                    <td>State member banks, branches and agencies of foreign banks (other than federal branches, federal agencies, and Insured State Branches of Foreign Banks), commercial lending companies owned or controlled by foreign banks, and organizations operating under section 25 or 25A of the Federal Reserve Act.</td>
                    <td>b. Federal Reserve Consumer Help Center<br/>
                        P.O. Box. 1200<br/>
                        Minneapolis, MN 55480
                    </td>
                </tr>
                <tr>
                    <td>c.</td>
                    <td>Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations</td>
                    <td>c. Division of Depositor and Consumer Protection<br/>
                        National Center for Consumer and Depositor Assistance<br/>
                        Federal Deposit Insurance Corporation<br/>
                        1100 Walnut Street, Box #11<br/>
                        Kansas City, MO 64106
                    </td>
                </tr>
                <tr>
                    <td>d.</td>
                    <td>Federal Credit Unions</td>
                    <td>d. National Credit Union Administration<br/>
                        Office of Consumer Financial Protection<br/>
                        1775 Duke Street<br/>
                        Alexandria, VA 22314
                    </td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td colSpan={2}>Air carriers</td>
                    <td>
                        Assistant General Counsel for Office of Aviation Consumer Protection
                        Department of Transportation<br/>
                        1200 New Jersey Avenue SE<br/>
                        Washington, DC 20590
                    </td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td colSpan={2}>Creditors Subject to the Surface Transportation Board</td>
                    <td>
                        Office of Public Assistance, Governmental Affairs, and Compliance<br/>
                        Surface Transportation Board<br/>
                        395 E Street SW<br/>
                        Washington, DC 20423
                    </td>
                </tr>
                <tr>
                    <td>5.</td>
                    <td colSpan={2}>Creditors Subject to the Packers and Stockyards Act, 1921</td>
                    <td>Nearest Packers and Stockyards Division Regional Office</td>
                </tr>
                <tr>
                    <td>6.</td>
                    <td colSpan={2}>Small Business Investment Companies</td>
                    <td>
                        Associate Administrator, Office of Capital Access<br/>
                        United States Small Business Administration<br/>
                        409 Third Street SW, Suite 8200<br/>
                        Washington, DC 20416
                    </td>
                </tr>
                <tr>
                    <td>7.</td>
                    <td colSpan={2}>Brokers and Dealers</td>
                    <td>
                        Securities and Exchange Commission<br/>
                        100 F Street NE<br/>
                        Washington, DC 20549
                    </td>
                </tr>
                <tr>
                    <td>8.</td>
                    <td colSpan={2}>Institutions that are members of the Farm Credit System</td>
                    <td>
                        Farm Credit Administration<br/>
                        1501 Farm Credit Drive<br/>
                        McLean, VA 22102-5090
                    </td>
                </tr>
                <tr>
                    <td>9.</td>
                    <td colSpan={2}>Retailers, Finance Companies, and All Other Creditors Not Listed Above</td>
                    <td>
                        Federal Trade Commission<br/>
                        Consumer Response Center<br/>
                        600 Pennsylvania Avenue NW<br/>
                        Washington, DC 20580<br/>
                        (877) 382-4357
                    </td>
                </tr>
                </tbody>
            </table>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label></Form.Label>
                        <Form.Check>
                            <Form.Check.Input required id="summaryOfRightsAck" onChange={handleChange('summaryOfRightsAck')} checked={values.summaryOfRightsAck} />
                            <Form.Check.Label>
                                I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA) and certify that I have read and understand this document.
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Next</Button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    );
}
