import React from 'react';

import TopNavigation from "@cloudscape-design/components/top-navigation";
import StatusIndicator from "@cloudscape-design/components/status-indicator";

export default function AppHeader(props) {

  const employer = props['employer'];

  const appStatus = 
    <StatusIndicator type="in-progress">
      In progress
    </StatusIndicator>;

  return (
    <TopNavigation
      identity={{
        href: "#",
        title: `${employer['longName']}`,
        logo: {
          src: `https://in4orm-assets.s3.amazonaws.com/${employer['logo']}`
        }
      }}
      utilities={[{
        type: "menu-dropdown",
        iconName: "status-in-progress",
        ariaLabel: "Application",
        title: "Application",
        text: "Application",
        description: appStatus,
        items: [{ 
          id: "employer",
          text: `${employer['longName']}` 
        }, { 
          id: "contact",
          text: `${employer['phone']}`
        }]
    }]} />
  );
}
