/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import NtAE_for_PE_Part1_English from './img/NtAE_for_PE_Part1_English.png';
import NtAE_for_PE_Part2_Spanish from './img/NtAE_for_PE_Part2_Spanish.png';
import SanFranFCO_Page_1 from './img/SanFranFCO_Page_1.png';
import SanFranFCO_Page_2 from './img/SanFranFCO_Page_2.png';

const NoticeCA = function(handleChange, values, employerName) {

    return (
        <div className="notice">
            <h6 className="strong">NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW</h6>
            <p>{employerName}, the "Company," intends to obtain information about you for employment screening purposes from a consumer reporting agency.
                Thus, you can expect to be the subject of "investigative consumer reports" obtained for employment purposes. Such reports may include information
                about your character, general reputation, personal characteristics and mode of living. With respect to any investigative consumer report from an
                investigative consumer reporting agency ("ICRA"), the Company may investigate the information contained in your employment application and other
                background information about you, including but not limited to obtaining a criminal record report, verifying references, work history, your social
                security number, your educational achievements, licensure, and certifications, your driving record, and other information about you, and interviewing
                people who are knowledgeable about you. The results of this report may be used as a factor in making employment decisions. The source of any
                investigative consumer report (as that term is defined under California law) will be InCheck, Inc., Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510,
                7500 West State Street, Suite 200, Wauwatosa, WI 53213, <a href="https://www.inchecksolutions.com/privacy-policy/" target="_blank">https://www.inchecksolutions.com/privacy-policy/</a>.
                The Company agrees to provide you with a copy of an investigative consumer report when required to do so under California law.</p>

            <p>Under California Civil Code section 1786.22, you are entitled to find out what is in the ICRA's file on you with proper identification, as follows:</p>

            <ul>
                <li>In person, by visual inspection of your file during normal business hours and on reasonable notice. You also may request a copy of the information in person.
                    The ICRA may not charge you more than the actual copying costs for providing you with a copy of your file.</li>
                <li>A summary of all information contained in the ICRA's file on you that is required to be provided by the California Civil Code will be provided to you via
                    telephone, if you have made a written request, with proper identification, for telephone disclosure, and the toll charge, if any, for the telephone call
                    is prepaid by or charged directly to you.</li>
                <li>By requesting a copy be sent to a specified addressee by certified mail. ICRAs complying with requests for certified mailings shall not be liable for
                    disclosures to third parties caused by mishandling of mail after such mailings leave the ICRAs.</li>
            </ul>

            <p>"Proper Identification" includes documents such as a valid driver's license, social security account number, military identification card, and credit cards.
                Only if you cannot identify yourself with such information may the ICRA require additional information concerning your employment and personal or family history
                in order to verify your identity.</p>

            <p>The ICRA will provide trained personnel to explain any information furnished to you and will provide a written explanation of any coded information contained in
                files maintained on you. This written explanation will be provided whenever a file is provided to you for visual inspection.</p>

            <p>You may be accompanied by one other person of your choosing, who must furnish reasonable identification. An ICRA may require you to furnish
               a written statement granting permission to the ICRA to discuss your file in such person's presence.</p>

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input id="caBackgroundCheckCopy" onChange={handleChange('caBackgroundCheckCopy')} checked={values.caBackgroundCheckCopy} />
                        <Form.Check.Label>
                            Please check this box if you would like to receive a copy of an investigative consumer report at no charge if one is obtained by the Company whenever you have a right to receive such a copy under California law.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="caBackgroundCheckAck" onChange={handleChange('caBackgroundCheckAck')} checked={values.caBackgroundCheckAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of the <strong>NOTICE REGARDING BACKGROUND CHECK PER CALIFORNIA LAW</strong> and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
};

const NoticeSFO = function(handleChange, values) {
    return (
        <div className="notice">
            <img src={SanFranFCO_Page_1} alt='' />
            <img src={SanFranFCO_Page_2} alt='' />

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="sfCityOrdinanceAck" onChange={handleChange('sfCityOrdinanceAck')} checked={values.sfCityOrdinanceAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of the <strong>San Francisco Fair Chance Ordinance</strong> and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
};

const NoticeLAX = function(handleChange, values) {
    return (
        <div className="notice">
            <img src={NtAE_for_PE_Part1_English} alt='' />
            <img src={NtAE_for_PE_Part2_Spanish} alt='' />

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="laCityOrdinanceAck" onChange={handleChange('laCityOrdinanceAck')} checked={values.laCityOrdinanceAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of the <strong>Los Angeles Fair Chance Ordinance</strong> and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
};

const NoticeNY = function(handleChange, values, employerName) {
    return(
        <div className="notice">
            <h6>NEW YORK CORRECTION LAW ARTICLE 23-A</h6>
            <br/>
            <h6>LICENSURE AND EMPLOYMENT OF PERSONS PREVIOUSLY CONVICTED OF ONE OR MORE CRIMINAL OFFENSES</h6>
            <br/>

            <p className='strong'>Section 750. Definitions.</p>
            <p className='strong'>751. Applicability.</p>
            <p className='strong'>752. Unfair discrimination against persons previously convicted of one or more criminal offenses prohibited.</p>
            <p className='strong'>753. Factors to be considered concerning a previous criminal conviction; presumption.</p>
            <p className='strong'>754. Written statement upon denial of license or employment.</p>
            <p className='strong'>755. Enforcement.</p>
            <hr/>

            <p><strong>Section 750. Definitions.</strong> For the purposes of this article, the following terms shall have the following meanings:</p>
            <p>(1) "Public agency" means the state or any local subdivision thereof, or any state or local department, agency, board or commission.</p>
            <p>(2) "Private employer" means any person, company, corporation, labor organization or association which employs ten or more persons.</p>
            <p>(3) "Direct relationship" means that the nature of criminal conduct for which the person was convicted has a direct bearing on his fitness or ability to perform one or more of the duties or responsibilities necessarily related to the license, opportunity, or job in question.</p>
            <p>(4) "License" means any certificate, license, permit or grant of permission required by the laws of this state, its political subdivisions or instrumentalities as a condition for the lawful practice of any occupation, employment, trade, vocation, business, or profession. Provided, however, that "license" shall not, for the purposes of this article, include any license or permit to own, possess, carry, or fire any explosive, pistol, handgun, rifle, shotgun, or other firearm.</p>
            <p>(5) "Employment" means any occupation, vocation or employment, or any form of vocational or educational training. Provided, however, that "employment" shall not, for the purposes of this article, include membership in any law enforcement agency.</p>

            <p><strong>Section 751. Applicability.</strong> The provisions of this article shall apply to any application by any person for a license or employment at any public or private employer, who has previously been convicted of one or more criminal offenses in this state or in any other jurisdiction, and to any license or employment held by any person whose conviction of one or more criminal offenses in this state or in any other jurisdiction preceded such employment or granting of a license, except where a mandatory forfeiture, disability or bar to employment is imposed by law, and has not been removed by an executive pardon, certificate of relief from disabilities or certificate of good conduct. Nothing in this article shall be construed to affect any right an employer may have with respect to an intentional misrepresentation in connection with an application for employment made by a prospective employee or previously made by a current employee.</p>
            <p><strong>Section 752. Unfair discrimination against persons previously convicted of one or more criminal offenses prohibited.</strong> No application for any license or employment, and no employment or license held by an individual, to which the provisions of this article are applicable, shall be denied or acted upon adversely by reason of the individual's having been previously convicted of one or more criminal offenses, or by reason of a finding of lack of  "good moral character" when such finding is based upon the fact that the individual has previously been convicted of one or more criminal offenses, unless:</p>

            <p>(1) There is a direct relationship between one or more of the previous criminal offenses and the specific license or employment sought or held by the individual; or</p>
            <p>(2) the issuance or continuation of the license or the granting or continuation of the employment would involve an unreasonable risk to property or to the safety or welfare of specific individuals or the general public.</p>

            <p className='strong'>Section 753. Factors to be considered concerning a previous criminal conviction; presumption.</p>
            <p>1. In making a determination pursuant to section seven hundred fifty-two of this chapter, the public agency or private employer shall consider the following factors:</p>
            <p>(a) The public policy of this state, as expressed in this act, to encourage the licensure and employment of persons previously convicted of one or more criminal offenses.</p>
            <p>(b) The specific duties and responsibilities necessarily related to the license or employment sought or held by the person.</p>
            <p>(c) The bearing, if any, the criminal offense or offenses for which the person was previously convicted will have on his fitness or ability to perform one or more such duties or responsibilities.</p>
            <p>(d) The time which has elapsed since the occurrence of the criminal offense or offenses.</p>
            <p>(e) The age of the person at the time of occurrence of the criminal offense or offenses.</p>
            <p>(f) The seriousness of the offense or offenses.</p>
            <p>(g) Any information produced by the person, or produced on his behalf, in regard to his rehabilitation and good conduct.</p>
            <p>(h) The legitimate interest of the public agency or private employer in protecting property, and the safety and welfare of specific individuals or the general public.</p>
            <p>2. In making a determination pursuant to section seven hundred fifty-two of this chapter, the public agency or private employer shall also give consideration to a certificate of relief from disabilities or a certificate of good conduct issued to the applicant, which certificate shall create a presumption of rehabilitation in regard to the offense or offenses specified therein.</p>

            <p><strong>Section 754. Written statement upon denial of license or employment.</strong> At the request of any person previously convicted of one or more criminal offenses who has been denied a license or employment, a public agency or private employer shall provide, within thirty days of a request, a written statement setting forth the reasons for such denial.</p>

            <p className='strong'>Section 755. Enforcement</p>
            <p>1. In relation to actions by public agencies, the provisions of this article shall be enforceable by a proceeding brought pursuant to article seventy-eight of the civil practice law and rules.</p>
            <p>2. In relation to actions by private employers, the provisions of this article shall be enforceable by the division of human rights pursuant to the powers and procedures set forth in article fifteen of the executive law, and, concurrently, by the New York city commission on human rights.</p>

            <h6>NOTICE REGARDING INVESTIGATIVE CONSUMER REPORTS PER NEW YORK LAW</h6>
            <p>With your permission and pursuant to New York General Business Law Section 380, {employerName}, the "Company," may request an investigative consumer report about you from a third party consumer reporting agency, in connection with your employment or application for employment (including independent contractor or volunteer assignments, as applicable). An "investigative consumer report" is a background report that includes information from personal interviews. The most common form of an investigative consumer report in connection with your employment is a reference check through personal interviews with sources such as your former employers and associates, and other information sources. The investigative consumer report may contain information concerning your character, general reputation, personal characteristics or mode of living. You may request more information about whether or not an investigative consumer report was requested, as well as information on the nature and scope of an investigative consumer report, if any, by contacting the Company in writing.</p>

            <p>You have the right to receive a copy of any investigative consumer report requested by the Company, upon its completion, by contacting the following consumer reporting agency:</p>

            <p>
                InCheck, Inc.<br/>
                7500 W. State Street, Suite 200,<br/>
                Wauwatosa, WI 53213 Phone: 414-727-1718, Fax: 414-727-5510<br/>
                <a href="https://www.inchecksolutions.com/privacy-policy/">https://www.inchecksolutions.com/privacy-policy/</a>
            </p>

            <h6>NOTICE FOR NEW YORK CITY APPLICANTS</h6>
            <p>By clicking the box below, you further authorize Company to provide you with a copy of your consumer report, the New York City Fair Chance Act Notice form, and any other documents, to the extent required by law, at the mailing address and/or email address you provide to the Company.</p>

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="nyCityFairChanceNoticeAck" onChange={handleChange('nyCityFairChanceNoticeAck')} checked={values.nyCityFairChanceNoticeAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of the NEW YORK CORRECTION LAW ARTICLE 23-A, the NOTICE REGARDING INVESTIGATIVE CONSUMER REPORTS PER NEW YORK LAW and the NOTICE FOR NEW YORK CITY APPLICANTS and certify that I have read and understand these documents.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
}

const NoticeMN = function(handleChange, values, employerName) {
    return(
        <div className="notice">
            <h6>
                NOTICE REGARDING CONSUMER<br/>
                REPORTS PER MINNESOTA LAW
            </h6>

            <p>{employerName}, the "Company," may obtain a consumer report from a third-party consumer reporting agency
                in connection with your prospective or continued employment (including independent contractor or
                volunteer assignments, as applicable). This may include procurement of an investigative consumer report,
                which may include information obtained through personal interviews. These reports may contain
                information regarding your criminal history, social security verification, motor vehicle records
                ("driving records"), verification of your education or employment history, or other background checks
                and which may include information concerning your character, general reputation, personal
                characteristics or mode of living. The most common form of an investigative consumer report in
                connection with your employment is a reference check through personal interviews with sources such as
                your former employers and associates, and other information sources. You have the right, upon written
                request, to receive a complete and accurate disclosure of the nature and scope of any consumer
                report.</p>

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input id="mnBackgroundCheckCopy" onChange={handleChange('mnBackgroundCheckCopy')} checked={values.mnBackgroundCheckCopy} />
                        <Form.Check.Label>
                            Please check this box to receive from InCheck, Inc., 7500 West State Street, Suite 200, Wauwatosa, WI, 53213, <a href="https://www.inchecksolutions.com/privacy-policy/" target="_blank">https://www.inchecksolutions.com/privacy-policy/</a>, Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510, a copy of any report furnished by InCheck, Inc. to the Company pursuant to your authorization.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="mnBackgroundCheckAck" onChange={handleChange('mnBackgroundCheckAck')} checked={values.mnBackgroundCheckAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of NOTICE REGARDING CONSUMER REPORTS PER MINNESOTA LAW and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>

        </div>
    );
}

const NoticeOK = function(handleChange, values, employerName) {
    return(
        <div className="notice">
            <h6>
                NOTICE REGARDING CONSUMER<br/>
                REPORTS PER OKLAHOMA LAW
            </h6>

            <p>
                Pursuant to 24 Oklahoma Statutes Section 148, {employerName}, the "Company," will request or order a consumer
                report from a third-party consumer reporting agency to be used in connection with your potential or continued
                employment. These reports may contain information regarding your criminal history, social security verification,
                motor vehicle records, verification of education or employment history, or other background checks. You have
                the right, upon request, to receive a copy of any consumer report requested by the Company for this purpose.
                The scope of this disclosure is all-encompassing, allowing the Company to obtain from any outside organization
                all manner of consumer reports throughout the course of your employment to the extent permitted by law.
            </p>

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input id="okBackgroundCheckCopy" onChange={handleChange('okBackgroundCheckCopy')} checked={values.okBackgroundCheckCopy} />
                        <Form.Check.Label>
                            Please check this box to receive from InCheck, Inc., 7500 West State Street, Suite 200, Wauwatosa, WI, 53213, <a href="https://www.inchecksolutions.com/privacy-policy/" target="_blank">https://www.inchecksolutions.com/privacy-policy/</a>, Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510, a copy of any report furnished by InCheck, Inc. to the Company pursuant to your authorization.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="okBackgroundCheckAck" onChange={handleChange('okBackgroundCheckAck')} checked={values.okBackgroundCheckAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of NOTICE REGARDING CONSUMER REPORTS PER OKLAHOMA LAW and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
}

const NoticeWA = function(handleChange, values, employerName) {
    return(
        <div className="notice">
            <h6>
                NOTICE REGARDING INVESTIGATIVE CONSUMER<br/>
                REPORTS PER WASHINGTON LAW
            </h6>

            <p>
                In connection with your prospective or continued employment, {employerName}, the "Company," may request an
                investigative consumer report from a third-party consumer reporting agency, which may include information
                as to your character, general reputation, personal characteristics or mode of living. An "investigative
                consumer report" is a background report that includes information from personal interviews. The most common
                form of an investigative consumer report in connection with your employment is a reference check through
                personal interviews with sources such as your former employers and associates, and other information sources.
            </p>

            <p>
                You may request in writing a complete and accurate disclosure of the nature and scope of any investigation
                procured. You may also request a summary of your rights under the Washington Fair Credit Reporting Act.
            </p>

            <p>
                The source of any investigative consumer report will be InCheck, Inc., 7500 West State Street, Suite 200,
                Wauwatosa, WI, 53213, <a href="https://www.inchecksolutions.com/privacy-policy/" target="_blank">https://www.inchecksolutions.com/privacy-policy/</a>,
                Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510.
            </p>

            <Row>
                <Form.Group>
                    <Form.Check>
                        <Form.Check.Input required id="waBackgroundCheckAck" onChange={handleChange('waBackgroundCheckAck')} checked={values.waBackgroundCheckAck} />
                        <Form.Check.Label>
                            I acknowledge receipt of NOTICE REGARDING INVESTIGATIVE CONSUMER REPORTS PER WASHINGTON LAW and certify that I have read and understand this document.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please acknowledge receipt of the disclosure.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
            </Row>
        </div>
    );
}

export default function Notices({previous, next, handleChange, values, employerName }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return (
        <div className="container-lg personal-info">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <h5>State And City Notices</h5>
                    <h5>APPLICANT MUST COMPLETE ALL OF THE FOLLOWING OPTIONS</h5>
                </Row>
                <Row>
                    <Form.Text as={Col} md="8">
                        1. I certify that I am an applicant applying to work in California or a resident of California?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ca-resident" id="ca-resident-yes" type='radio' onChange={handleChange('noticeCAResident')} checked={values.noticeCAResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ca-resident" id="ca-resident-no" type='radio' onChange={handleChange('noticeCAResident')} checked={values.noticeCAResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeCAResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeCA(handleChange, values, employerName) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        2. I certify that I am an applicant applying to work in or a resident of San Francisco?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-sf-resident" id="sf-resident-yes" type='radio' onChange={handleChange('noticeSFResident')} checked={values.noticeSFResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-sf-resident" id="sf-resident-no" type='radio' onChange={handleChange('noticeSFResident')} checked={values.noticeSFResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeSFResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeSFO(handleChange, values) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        3. I certify that I am an applicant applying to work in or a resident of Los Angeles?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-la-resident" id="la-resident-yes" type='radio' onChange={handleChange('noticeLAResident')} checked={values.noticeLAResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-la-resident" id="la-resident-no" type='radio'  onChange={handleChange('noticeLAResident')} checked={values.noticeLAResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeLAResident === "Y" && (
                    <Row>
                        <div className="col-md-11">
                            { NoticeLAX(handleChange, values) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        4. I certify that I am an applicant applying to work in New York or a resident of New York?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ny-resident" id="ny-resident-no" type='radio' onChange={handleChange('noticeNYResident')} checked={values.noticeNYResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ny-resident" id="ny-resident-no" type='radio' onChange={handleChange('noticeNYResident')} checked={values.noticeNYResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeNYResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeNY(handleChange, values, employerName) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        5. I certify that I am an applicant applying to work in Minnesota or a resident of Minnesota?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-mn-resident" id="mn-resident-no" type='radio' onChange={handleChange('noticeMNResident')} checked={values.noticeMNResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-mn-resident" id="mn-resident-no" type='radio' onChange={handleChange('noticeMNResident')} checked={values.noticeMNResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeMNResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeMN(handleChange, values, employerName) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        6. I certify that I am an applicant applying to work in Oklahoma or a resident of Oklahoma?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ok-resident" id="ok-resident-no" type='radio' onChange={handleChange('noticeOKResident')} checked={values.noticeOKResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-ok-resident" id="ok-resident-no" type='radio' onChange={handleChange('noticeOKResident')} checked={values.noticeOKResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeOKResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeOK(handleChange, values, employerName) }
                        </div>
                    </Row>
                )}
                <Row>
                    <Form.Text as={Col} md="8">
                        7. I certify that I am an applicant applying to work in Washington or a resident of Washington?
                    </Form.Text>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-wa-resident" id="wa-resident-no" type='radio' onChange={handleChange('noticeWAResident')} checked={values.noticeWAResident === "Y"} value="Y" />
                            <Form.Check.Label>Yes</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Check>
                            <Form.Check.Input required name="notice-wa-resident" id="wa-resident-no" type='radio' onChange={handleChange('noticeWAResident')} checked={values.noticeWAResident === "N"} value="N" />
                            <Form.Check.Label>No</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please select an option.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                { values.noticeWAResident === "Y" && (
                    <Row>
                        <div className="col-md-10">
                            { NoticeWA(handleChange, values, employerName) }
                        </div>
                    </Row>
                )}
            <Row>
                <Form.Group as={Col} md="12">
                    <Button onClick={previous} className="btn-secondary">Back</Button>
                    <Button type="submit">Next</Button>
                </Form.Group>
            </Row>
            </Form>
        </div>
    );
};
