import React, { useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import ReCAPTCHA from "react-google-recaptcha";

const Home = ({ next, employerName }) => {

    const captchaRef = useRef(null);

    var token;

    const handleSubmit = (event) => {
        token = captchaRef.current.getValue();
        if (!token) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
    }

    return (
        <div>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Instructions</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Thank you for showing an interest in working at {employerName}. 
                            Please fill in your personal contact information below to continue to the next step of the hiring process. 
                        </p>
                        <span>For best user experience:</span>
                        <ul>
                            <li>Use Google Chrome or Mozilla Firefox.</li>
                            <li>Do not use autofill to complete any portion of this application.</li>
                            <li>Tab through each field.</li>
                            <li>Check all fields for accuracy prior to submission.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Application For Employment</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            We are an Equal Opportunity Employer, including disability/veterans. 
                            All persons shall have the opportunity to be considered for employment without regard to their race, color, 
                            creed, religion, national origin, ancestry, citizenship status, age, disability, sex, gender, gender identity or 
                            expression, sexual orientation, veteran status, genetic information or any other characteristic protected by 
                            applicable federal, state or local laws.
                        </p>
                        <p>
                            We will endeavor to make a reasonable accommodation to the known physical or mental limitations of a qualified 
                            applicant with a disability unless the accommodation would impose an undue hardship on the operation of our business. 
                            If you believe you require such assistance to complete this form or to participate in an interview, please let us know.
                        </p>
                        <p>
                            To be completed for all positions involving the operation of a company-owned motor vehicle.
                        </p>
                        <p>
                            <b>* = required field</b>
                        </p>
                        <p>
                            EOE, including disability/vets
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div>
                <Form onSubmit={handleSubmit}>
                    <Row></Row>
                    <Row>
                        <Form.Group as={Col} md="12" className='d-flex align-items-center justify-content-center'>
                            <ReCAPTCHA 
                                sitekey='6LdVmDQkAAAAADqHWFwZiYtOj0EkG-8L8l12mNo9'
                                ref={captchaRef} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className='d-flex align-items-center justify-content-center'>
                            <Form.Label>Please complete the reCAPTCHA.</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className='d-flex align-items-center justify-content-center'>
                            <Button type="submit">Next</Button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default Home;