/* eslint-disable */

import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

export default function Success({ clearState }) {

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        window.scrollTo(0, 0);

        const autoClose = () => {
            const delay = sleep(20000);

            delay.then(() => {
                clearState();
            });
        }
        
        autoClose();
    }, []);

    return(
        <div className="container-lg success-box">
            <Alert key='success' variant='success'>
                <Alert.Heading>Thank you! Your application has been submitted successfully.</Alert.Heading>
                <p>
                    No further steps are required. Please close this window or click the button below to return to the home page.
                </p>
                <Button onClick={clearState}>Return to Home</Button>
            </Alert>
        </div>
    );
}
