/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function BackgroundDisclosure({ previous, next, handleChange, values, employerName }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return(
        <div className="container-lg personal-info">
            <h6 className="strong middle">DISCLOSURE REGARDING BACKGROUND INVESTIGATION</h6>
            <p className="middle">[IMPORTANT -- PLEASE READ CAREFULLY BEFORE SIGNING ACKNOWLEDGMENT]</p>

            <p>
                ProHealth Care, the "Company," may obtain information about you from a third-party consumer reporting agency for employment purposes. Thus, you may be the subject of a "consumer report" which may include information about your character, general reputation, personal characteristics, and/or mode of living. These reports may contain information regarding your criminal history, social security verification, motor vehicle records ("driving records"), verification of your education or employment history, credit history, or other background checks.
            </p>

            <p>
                These investigations will be conducted by InCheck, Inc., Phone: 414-727-1718 / 866-265-9426, Fax: 414-727-5510, 7500 West State Street, Suite 200, Wauwatosa, WI 53213, <a href="https://www.inchecksolutions.com/privacy-policy/">https://www.inchecksolutions.com/privacy-policy/</a>. 
            </p>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group>
                        <Form.Check>
                            <Form.Check.Input required id="bgCheckDisclosureAck" onChange={handleChange('bgCheckDisclosureAck')} checked={values.bgCheckDisclosureAck} />
                            <Form.Check.Label>
                                I acknowledge receipt of the DISCLOSURE REGARDING BACKGROUND INVESTIGATION and certify that I have read and understand this document.
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Next</Button>
                    </Form.Group>
                </Row>
            </Form>

        </div>
    );
};
