/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';

import PersonalInfo from './ProHealthPersonalInfo';

export default function ProHealthApplication({ previous, next, handleChange, values, readonly=false }) {

    async function sendData() {
        return API.post('APIG', '/', {
            body: values
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            sendData()
                .then(function(response) {
                    next();
                })
                .catch(function(error) {
                    //TODO: handle error properly
                    next();
                });
        }
    }

    const generateEducationSection = function(type, longName) {
      const prefix = 'inst';
      const nameField = prefix + 'Name' + type;
      const cityStateField = prefix + 'CityState' + type;
      const graduatedField = prefix + 'Graduated' + type;
      const datesAttendedField = prefix + 'DatesAttended' + type;
      const degreeField = prefix + 'Degree' + type;
      const majorField = prefix + 'Major' + type;
      const gpaField = prefix + 'Gpa' + type;
      const gradNameField = prefix + 'GradName' + type;

      return <>
            <Row>
              <Form.Label>{longName}</Form.Label>
            </Row>
            <Row>
                <Form.Group as={Col} md="4">
                    <Form.Label>Name of Institution</Form.Label>
                    <Form.Control type="text" maxLength={50} onChange={handleChange(nameField)} value={values[nameField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>City, State</Form.Label>
                    <Form.Control type="text" maxLength={30} onChange={handleChange(cityStateField)} value={values[cityStateField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid city and state.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>Did you graduate?</Form.Label>
                    <Form.Group>
                        <Form.Check inline type="radio" label="Yes" name={graduatedField} onChange={handleChange(graduatedField)} checked={values[graduatedField] === 'Y'} value="Y" />
                        <Form.Check inline type="radio" label="No" name={graduatedField} onChange={handleChange(graduatedField)} checked={values[graduatedField] === 'N'} value="N" />
                    </Form.Group>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>Dates Attended</Form.Label>
                    <Form.Control type="text" maxLength={30} onChange={handleChange(datesAttendedField)} value={values[datesAttendedField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide valid dates.
                    </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="2">
                    <Form.Label>Degree</Form.Label>
                    <Form.Select className="form-select" name={degreeField} value={values[degreeField]} onChange={handleChange(degreeField)}>
                      <option value="">Select an option...</option>
                      <option value="GED">GED</option>
                      <option value="HighSchoolDiploma">High School Diploma</option>
                      <option value="Certificate">Certificate</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Associates">Associate's</option>
                      <option value="Bachelors">Bachelor's</option>
                      <option value="Masters">Master's</option>
                      <option value="Doctoral">Doctoral</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>Major</Form.Label>
                    <Form.Control type="text" maxLength={30} onChange={handleChange(majorField)} value={values[majorField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a major.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="1">
                    <Form.Label>GPA</Form.Label>
                    <Form.Control type="text" maxLength={5} onChange={handleChange(gpaField)} value={values[gpaField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid GPA.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Name graduated under, if different</Form.Label>
                    <Form.Control type="text" maxLength={60} onChange={handleChange(gradNameField)} value={values[gradNameField]} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row><hr/></Row>
            </>;
    }

    return(
        <div className="container-lg personal-info">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <PersonalInfo next={undefined} handleChange={handleChange} values={values} readonly={readonly} />
                {!values.volunteer &&
                  <div>
                  <Row>
                  </Row>
                  <Row>
                    <h6 className="strong middle">Education Verification</h6>
                    <p className="middle">Please list the education levels completed.</p>
                  </Row>
                  {generateEducationSection('HS', 'High School')}
                  {generateEducationSection('Tech', 'Technical')}
                  {generateEducationSection('College', 'College')}
                  {generateEducationSection('Grad', 'Graduate')}
                  <Row>
                    <h6 className="strong middle">Additional Education</h6>
                    <p className="middle">Please summarize any additional education not provided above.</p>
                    <Form.Control as="textarea" rows={3} maxLength={500} onChange={handleChange('additionalEducation')} value={values.additionalEducation} required />
                  </Row>
                  <Row>
                  </Row>
                  </div>
                }
                <Row>
                    <Form.Group>I certify that the information provided on the preceding forms is truthful, accurate, and complete to the best of my knowledge. I understand that misstatements and inaccuracies may be cause for suspension or rejection of my volunteer candidacy.</Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="2">
                        <Form.Label></Form.Label>
                        <Form.Check>
                            <Form.Check.Input required id="consent" onChange={handleChange('appAck')} checked={values.bgInfoDisclosureAck} />
                            <Form.Check.Label>
                                I agree
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Enter first and last name:</Form.Label>
                        <Form.Control type="text" id="signature" maxLength={60} required onChange={handleChange('appESig')} value={values.bgInfoDisclosureESig} />
                        <Form.Control.Feedback type="invalid">
                            Please provide an electronic signature.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Submit</Button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    );
}
