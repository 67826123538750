import React from 'react';
import Footer from './Footer';
import Workflow from './Workflow';

import './App.css';
import { Amplify } from 'aws-amplify'
import "@cloudscape-design/global-styles/index.css"

import workflowData from './resources/workflows.json';
import AppHeader from './Header';

Amplify.configure({
    API: {
        endpoints: [{
            name: 'APIG',
            endpoint: 'https://gma1zjdj1f.execute-api.us-east-1.amazonaws.com/Stage'
        }]
    }
});

export interface EmployerInfo {
  readonly longName: string;
  readonly phone: string;
  readonly fax: string;
  readonly address: string;
  readonly logo: string;
}

function App() {

  const params = new URLSearchParams(location.search);
  const clientId: keyof typeof workflowData = params.get('client') as keyof typeof workflowData || 'alm';
  const workflowId = params.get('wf') || '';
  const hiringManagerId = params.get('hm') || '';
  const volunteer  = params.get('volunteer') === "true";

  const clientData: any = workflowData[clientId];
  const employer: EmployerInfo = workflowId ? clientData[workflowId] : clientData;

  return (
    <div className="App">

      <AppHeader employer={employer} />

      <Workflow employer={employer} clientId={clientId} workflowId={workflowId} hiringManagerId={hiringManagerId} volunteer={volunteer} />

      <Footer />
    </div>
  );
};

export default App;
