/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function InvestigativeConsumerReport({ previous, next, handleChange, values, employerName }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            next();
        }
        setValidated(true);
    }

    return (
        <div className="container-lg">
            <h6 className="middle strong">DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT</h6>
            <p>{employerName}, the "Company," may request an investigative consumer report about you from a third-party consumer reporting agency, in connection with your employment or application for employment (including independent contractor or volunteer assignments, as applicable) and throughout your employment if you are hired or retained, as allowed by law. An "investigative consumer report" is a background report that includes information from personal interviews (except in California, where that term includes background reports with or without information obtained from personal interviews). The most common form of an investigative consumer report in connection with your employment is a reference check through personal interviews with sources such as your former employers and associates, and other information sources. The investigative consumer report may contain information concerning your character, general reputation, personal characteristics, or mode of living.</p>

            <p>You have the right, upon written request made within a reasonable time, to request from the Company (1) whether an investigative consumer report has been obtained about you, (2) disclosure of the nature and scope of any investigative consumer report and (3) a copy of your report. These reports will be prepared by InCheck, Inc., (414) 727-1718, 7500 West State Street, Suite 200, Wauwatosa, WI 53213, <a href="https://www.inchecksolutions.com/privacy-policy/" target="_blank">https://www.inchecksolutions.com/privacy-policy/</a></p>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group>
                        <Form.Check>
                            <Form.Check.Input required id="consent" onChange={handleChange('investigativeConsumerReportAck')} checked={values.investigativeConsumerReportAck} />
                            <Form.Check.Label>
                                I acknowledge receipt of the <span className="strong">DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT</span> and certify that I have read and understand this document.
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Please acknowledge receipt of the disclosure.
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Today's date: </Form.Label>
                        <Form.Control type="text" readonly plaintext defaultValue={values.eSignatureDate} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Button onClick={previous} className="btn-secondary">Back</Button>
                        <Button type="submit">Next</Button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    );
};
